<script lang="ts" setup>
// TODO use core-dashboard
import { forgdAcademyNav } from '#academy/utils/nav'

import { showSupportModal } from '~/utils/nav'
import { topLevelMenu } from '../utils/nav'

withDefaults(defineProps<Options>(), { contact: true, containerClass: 'max-w-[1200px]' })

interface Options {
  title?: string
  icon?: string
  cta?: string
  contact?: boolean
  containerClass?: false | string
}

useHead({
  templateParams: {
    siteName: 'Forgd Liquidity',
  },
})

const router = useRouter()

const groups = [
  {
    key: 'tools',
    label: 'Liquidity Tools',
    commands: (topLevelMenu.value[0] as { to: string }[]).filter(i => !!i.to),
  },
]

const displayFooter = computed(() => {
  const availableRoutes = [
    '/active-rfqs',
    '/closed-rfqs',
    '/leaderboards',
    '/leaderboards/depth',
    '/leaderboards/depth',
    '/leaderboards/volume',
    '/leaderboards/spread',
    '/monitor-active-projects',
    '/my-engagements',
    '/leaderboard-opt-ins',
  ]
  return availableRoutes.includes(router.currentRoute.value.path)
})
</script>

<template>
  <NuxtLayout name="core-dashboard" v-bind="$props">
    <template #header>
      <slot name="header" />
    </template>
    <template #slideover="{ modelValue, navTitle: NavTitle, navList: NavList }">
      <nav v-if="modelValue === 'forgd-academy'" aria-label="Forgd Academy Navigation">
        <div class="bg-white sticky top-0 p-4 border-b border-b-neutral-600" :style="{ zIndex: 2 }">
          <div class="flex items-center gap-2 text-forgd-primary-900">
            <UIcon class="w-5 h-5" name="i-heroicons-academic-cap" />
            <div class="text-sm font-semibold">
              <div>Forgd Academy</div>
            </div>
          </div>
        </div>
        <div v-for="(item, key) in forgdAcademyNav" :key="key" class="p-4">
          <component :is="NavTitle" :label="item.label" :to="item.to" :icon="item.icon" />
          <component :is="NavList" :items="item.children" />
        </div>
      </nav>
    </template>
    <template v-if="$slots.sidebar" #sidebar>
      <slot name="sidebar" />
    </template>
    <template v-if="displayFooter" #footer>
      <div class="px-[1.875rem] py-[3.125rem] border-t border-forgd-bgd-600 w-full mx-auto">
        <div class="flex">
          <div class="w-8/12">
            <p class="text-forgd-primary-300 text-base font-semibold mb-4">
              Build Your Reputation
            </p>
            <p class="text-forgd-primary-900 leading-tight text-4xl font-normal mb-8">
              Streamline your operations & improve your Leaderboard rank by providing data to Forgd.
            </p>
            <div class="flex flex-col gap-1 items-start">
              <div class="text-sm">
                Need Support?
              </div>
              <UiButton variant="solid" size="lg" @click="showSupportModal = true">
                Get in touch with Forgd
              </UiButton>
            </div>
          </div>
          <div class="flex justify-end items-center w-4/12">
            <img src="/cubes.svg" alt="Cubes" class="w-[13.5rem]" loading="lazy">
          </div>
        </div>
      </div>
    </template>
    <slot />
    <UModal
      v-model="showSupportModal"
      :ui="{
        width: 'sm:max-w-[30rem]',
      }"
    >
      <UiCard>
        <div class="relative">
          <div class="absolute top-0 right-0">
            <UIcon name="i-heroicons-x-mark" class="w-6 h-6 text-primary-900 cursor-pointer" @click="showSupportModal = false" />
          </div>
          <div class="flex flex-col items-start gap-2.5 py-1">
            <img src="../public/telegram.svg" class="h-[6.875rem]" alt="Telegram">
            <div class="text-xl text-primary-900 font-semibold">
              Connect with Forgd on Telegram
            </div>
            <p class="text-sm text-gray-600">
              Get support, share feedback, and more through your dedicated Telegram support channel. Reach out to us anytime!
            </p>
            <UiButton
              size="md"
              variant="solid"
              class="w-full mt-2.5"
              to="https://t.me/+G3a5lwRFo5RiNmE1"
              @click="showSupportModal = false"
            >
              Open Telegram
            </UiButton>
          </div>
        </div>
      </UiCard>
    </UModal>
  </NuxtLayout>
  <ClientOnly>
    <LazyCoreSearchModal :groups="groups" />
  </ClientOnly>
</template>
